@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

:root {
  --bg-color: black;
}

body, #root {
  margin: 0;
  height: 100vh !important;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-color);
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  color: rgba(30, 30, 30, 0.76);
  text-decoration: none;
}

.container-fluid {
  height: 100vh;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
}

.content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 70px 10px 70px;
  background: transparent;
  margin-top: 10vh;
}

.fw-bold-important {
  font-weight: bold !important;
}

.size-24px {
  font-size: 1.4em;
}

.copied-toast {
  color: white;
}

.copied-toast div {
  background-color: #727272;
  padding: 4px;
  border-radius: 2px;
}

@media (max-width: 768px) { 
  .content {
    padding: 0 0 10px 0;
  }
  .size-24px {
    font-size: 1em;
  }
}

h1.name {
  font-family: 'Pacifico', cursive;
  font-size: 15em;
  font-weight: bolder;
  color: rgba(255, 255, 255, 0.8);
}

h1.name:hover {
    color: rgba(255, 255, 255, 0.9);
}

.Footer {
  padding: 30px 50px 30px 50px;
  color: white;
  font-family: Inter;
  bottom: 0;
  width: 100%;
}

.FooterFa {
  font-size: 1.5em;
  margin-left: 10px;
}

.copyright {
  color: rgba(255, 255, 255, 0.51);
  font-size: 12px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .Footer {
    font-size: .8em;
    padding: 15px;
  }
  .FooterFa {
    font-size: 1.5em;
  }
}